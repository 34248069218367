import React from 'react'
import tw from 'twin.macro'
import { useStateContext } from '@context/stateContext'
import {
  Subheader,
  OurValuesSection,
  OurOfficeSection,
  CurrentJobsSection,
  OfferSummary,
} from '@modules'
import { Spacer } from '@elements'

const Index = () => {
  const {
    content: {
      pages: {
        career: {
          sections,
          subpages: { shared },
        },
      },
    },
  } = useStateContext()

  return (
    <Container>
      <Subheader data={sections.subheader} />
      <Spacer className="pb-10 md:pb-20 lg:pb-40 xl:pb-40" />
      <OurValuesSection data={sections.cards} />
      <Spacer className="pb-10 md:pb-20 lg:pb-40 xl:pb-40" />
      {/* <CurrentJobsSection data={sections.jobList} /> */}
      <OurOfficeSection data={sections.officeGallery} />
      {/* <ContactForm data={sections.form} /> */}
      <OfferSummary data={shared.sections.offerSummary} />
    </Container>
  )
}

export default Index

const Container = tw.div`w-full bg-background`
